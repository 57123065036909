import * as React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Billboard from "../components/billboard"
import Img from "gatsby-image"

const aboutPage = props => (
  <Layout location={props.location}>
    <Seo
      title="ABOUT"
      keywords={[
        `XANDOR`,
        `Xandor`,
        `Xandor Bio`,
        `Xandor Biography`,
        `Xandor Music`,
        `Xandor EDM`,
        `Xandor Artist`,
        `Xandor Musical Artist`,
        `Xandor Instagram`,
        `Xandor Spotify`,
        `Xandor Official website`,
        `Xandor Soundcloud`,
        `Xandor DJ`,
        `Xandor Boston`,
        `Xandor Xicay`,
        `Xandormusic`,
        `Xandor electronic music`,
      ]}
      description="XANDOR is a music producer and DJ igniting the current and future house pop music scene with his unique silky, sensual soundscapes. The son of a renowned saxophone player, music has played a big part in Xandor's life. Xandor's inspiration to make electronic music started at a young age with a passion that led to professional training and over years of focused dedication."
    />
    <Billboard image={props.data.heroImage.childImageSharp.fluid} />
    <Container>
      <section className="about">
        <Row className=" align-items-center">
          <Col className="" lg="4">
            <Img
              className="rounded-circle"
              fluid={props.data.xandorImage.childImageSharp.fluid}
              alt="Xandor Profile"
            />
          </Col>
          <Col className="about-text-wrapper" lg="8">
            <h1 className="text-left mb-4">About Xandor</h1>
            <p>
              <strong>XANDOR</strong> is a music producer and DJ igniting the
              current and future house pop music scene with his unique silky,
              sensual soundscapes.
            </p>
            <p>
              The son of a renowned saxophone player, music has played a big
              part in <strong>Xandor's</strong> life. Xandor's inspiration to
              make electronic music started at a young age with a passion that
              led to professional training and over years of focused dedication.
            </p>
            <p>
              He has performed at some of the most popular venues in Boston.{" "}
              <strong>XANDOR</strong> released his debut collaboration with Will
              Jay, titled "Meet You There," an EDM/Pop track that has garnered
              over 200k streams across 92 countries worldwide.
            </p>
            <p>
              <strong>XANDOR's</strong> influences range from ZHU's seductively
              smooth sounds to the aggressive electronic jaunts from JAUZ and
              other associated acts, including The Chainsmokers, DJ Snake, Tame
              Impala, Madeon, Skrillex, Rüfüs Du Sol, and ODESZA.
            </p>
            <p>
              <strong>XANDOR</strong> has also become a popular live attraction,
              with plans for an upcoming album released very soon.
            </p>
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default aboutPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    xandorImage: file(relativePath: { eq: "xandor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1365, maxHeight: 1365, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
